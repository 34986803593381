import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    //Всего записей
    listCount: 0,
    //Список игр
    list: [],
    //Величина ограничения результатов запроса
    listLimit: 10,
    //Токен игры
    launchToken: '',

    //Флаг новых игр
    isNewFilter: false,
    //Флаг популярных игр
    isFavoritesFilter: false,
    //Искомая строка
    searchString: '',
    //Строка фильтров
    additionaFilters: '',

    isDemoRunGame: false,
    gameDescription: null,

    //Список ТОП игр за месяц
    topMonthGames: [],
    //Список ТОП выигрышей за месяц
    topMonthWins: [],
    //Список ТОП выигрышей за день
    topTodayWins: [],
    fastSearchResults: {},
};

export const games = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};