<template lang="pug">
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'app',
  computed: {
    ...mapGetters({
      userLanguage: 'user/getLanguage',
      defaultLanguage: 'user/getDefaultLanguage',
      isSigninVisible: 'auth/isSigninVisible',
      isSignupVisible: 'auth/isSignupVisible',
      modalMessage: 'main/getModalMessage',
      pages: 'main/getPages',
      isAuthUser: 'auth/isAuthUser',
      favicon: 'main/getFavicon',
      brand: 'main/getBrand',
      openSection: 'personal/getOpenSection',
      getNotifications: 'personal/getNotifications',
      list: 'games/getList',
      userProfile: 'user/getProfile'
    }),
    isBanner1Visible() {
      //@TODO thinking of using one variable to switch Banner1
      // return !this.pages['personal'] && !this.pages['games']
      //     && !this.pages['personalGame'] && !this.pages['termsOfUse']
      //     && !this.pages['privacyPolicy'] && !this.pages['bonusPolicy']
      //     && !this.pages['paymentMethods'] ;
      return this.pages.root;
    },
    getNotifyMessages(){
      return Object.values(this.getNotifications);
    },
    showNotifications() {
      return window.innerWidth > 1279 && this.getNotifyMessages.length > 0;
    },
    getUserId() {
      return this.userProfile != null && this.userProfile.hasOwnProperty('id') ? this.userProfile.id : null;
    },
  },
  sockets: {
    connect() {
      if (this.getUserId) {
        this.$socket.client.emit('setUser', { id: this.getUserId });
      }
    },
    notify(data) {
      this.ADD_SOCKET_NOTIFICATION(data);
      data.status = 'received';
      this.$socket.client.emit('updateNoticeStatus', data);
    },
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'user/fetchProfile',
      fetchInitSetting: 'main/fetchInitSetting',
      doLogout: 'auth/doLogout',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_LANGUAGE: 'user/SET_LANGUAGE',
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      ADD_SOCKET_NOTIFICATION: 'personal/ADD_SOCKET_NOTIFICATION',
      SET_FAST_SEARCH_RESULTS: 'games/SET_FAST_SEARCH_RESULTS',
    }),
  },
  async created() {
    window.onload = function () {
      document.getElementById('spinner').classList.add('loaded');
    };

    this.fetchUserProfile();

    let fetchUserProfile = async () => {
      if (this.isAuthUser) {
        await this.fetchUserProfile();
      }
      setTimeout(fetchUserProfile, 30000);
    };
    fetchUserProfile();
    
    this.SET_OPEN_PAGE('root');
    let fetchInit = async () => {
      await this.fetchInitSetting();
      setTimeout(fetchInit, 900000); //15min
    };
    await fetchInit();

    if (!this.brand.is_change_lang) {
      this.SET_LANGUAGE(this.defaultLanguage);
    }
    const language= this.userLanguage;
    this.$i18n.locale = language.split('-')[0];

    document.querySelector('title').innerText = this.brand.name;
    document.querySelector('link[rel="icon"]').removeAttribute('href');
    document.querySelector('link[rel="icon"]').setAttribute('href', `../favicons/${this.favicon}`);
  },
  watch: {
    async isAuthUser(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$socket.client.emit('setUser', { id: this.getUserId });
      }
      if (!newValue && oldValue) {
        this.$socket.client.emit('unsetUser');
        await this.doLogout();
      }
    },
    '$route': {
      deep: true,
      async handler(to, from) {
        if (from.name === 'personal' && to.name !== 'personal' && this.openSection !== '') {
          this.SET_OPEN_SECTION('');
        }
        /**
         * если роутинг меняется при клике на стрелку назад в браузере,
         * автоматически корректируем запси в объекте pages
         */
        else if (from.name === 'root' && to.name !== 'root') {
          this.SET_OPEN_PAGE(to.name);
        }
        else if (from.name == null && to.name != null) {
          this.SET_OPEN_PAGE(to.name);
        }
      }
    },
  },
  mounted() {
    const app = document.querySelector('#app');

    if (app) {
      app.addEventListener('click', (e) => {
        const targetClass = e.target.className;
        const searchBlock = document.querySelector('.search-wrapper__field');

        if (searchBlock && searchBlock.classList.contains('extended')) {
          if (targetClass !== 'search__field' && targetClass !== 'btn-search' && targetClass !== 'result-content-wrapper'
              && targetClass !== 'result__title' && targetClass !== 'result-image') {
            searchBlock.classList.remove('extended');
            this.SET_FAST_SEARCH_RESULTS({});
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>