import {clientApiAuth} from '@/utils/clientApiAuth';
import i18n from '@/i18n';

export const doPhoneActivateRequest = async ({commit}, phone) => {
    let response;

    try {
        response = await clientApiAuth('post', 'profile/phone-activate-request', {'phone': phone});

        const {error, message} = response.data;
        const verifyPhoneInputFields = [
            {
                placeholder: i18n.t('auth.twoFAPlaceholder'),
                name: 'verifyPhone',
                it_pass: true,
                regexp: new RegExp(/[0-9|a-z]{4}/),
                equal_name: '',
                typeString: 'text',
                errorText: '',
                value: null,
            },
        ];

        if (error != 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                input_fields_title: i18n.t('profile.editProfile.verifyPhoneLabel'),
                buttons: 'ok',
                title: i18n.t('profile.editProfile.verifyPhoneLabel'),
                text: message,
                input_fields: verifyPhoneInputFields,
                accept_button_text: i18n.t('auth.sendPasswordButton'),
                accept_action_func: 'verifyPhone',
                params: {new_phone: phone}
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
