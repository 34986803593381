import {clientApi} from '@/utils/clientApi';

export const fetchLastWins = async () => {
    try {
        let response = await clientApi(
            'get',
            'top-statistic/top-game-win'
        );
        if (response && typeof response.data === 'object' && response.data !== null) {
            //let data = [
            //     {id:1, win:200,currency:'RUB',game_id:670,game_en:'Sun-tech-nick1',game_ru:'Сантехник1',text:'Look who`s winning now -Vas** Just won on Sun-tech-nick',ts:1635411166},
            //     {id:2, win:900,currency:'RUB',game_id:630,game_en:'Sun-tech-nick2',game_ru:'Сантехник2',text:'Follow for Vas**! He won on Sun-tech-nick right now',ts:1635411158},
            //     {id:3, win:500,currency:'RUB',game_id:633,game_en:'Sun-tech-nick3',game_ru:'Сантехник3',text:'Follow for Vas***! He won on Sun-tech-nick right now',ts:1635411151},
            //     {id:4, win:900,currency:'RUB',game_id:630,game_en:'Sun-tech-nick4',game_ru:'Сантехник4',text:'Follow for Vas**! He won on Sun-tech-nick right now',ts:1635411158},
            //     {id:5, win:900,currency:'RUB',game_id:630,game_en:'Sun-tech-nick5',game_ru:'Сантехник5',text:'Follow for Vas**! He won on Sun-tech-nick right now',ts:1635411158}
            // ];
            return response.data.slice(0, 5);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
    return [];
};