import {clientApiAuth} from '@/utils/clientApiAuth';
import i18n from '@/i18n';

export const doEmailActivateRequest = async ({commit}, email) => {
    let response;

    try {
        response = await clientApiAuth('post', 'profile/email-activate-request', {'email': email});

        const {error, message} = response.data;
        const verifyEmailInputFields = [
            {
                placeholder: i18n.t('auth.twoFAPlaceholder'),
                name: 'verifyEmail',
                it_pass: true,
                regexp: new RegExp(/[0-9|a-z]{4}/),
                equal_name: '',
                typeString: 'text',
                errorText: '',
                value: null,
            },
        ];

        if (error != 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                input_fields_title: i18n.t('profile.editProfile.verifyEmailLabel'),
                buttons: 'ok',
                title: i18n.t('profile.editProfile.verifyEmailLabel'),
                text: message,
                input_fields: verifyEmailInputFields,
                accept_button_text: i18n.t('auth.sendPasswordButton'),
                accept_action_func: 'verifyEmail',
                params: {new_email: email}
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
