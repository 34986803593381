import {clientApi} from '@/utils/clientApi';
import cookie from 'vue-cookies';
import i18n from '@/i18n';

export const doLogin = async ({ commit, rootGetters, dispatch, state }, payload) => {
  let response;
  let currentLang = rootGetters['user/getLanguage'];

  try {
    response = await clientApi('post', `user/login?lang=${currentLang}`, {data: payload});
  } 
  catch (error) {
    console.log('error = ', error);
  }

  const { error, message, token, id } = response.data;

  if (error === 0) {
    cookie.set('accessToken', token);
    cookie.set('playerId', id);
    commit('SET_SIGNIN_VISIBLE', false);
    state.authUser = true;
    await dispatch('user/fetchBalance', null, { root: true });
    await dispatch('user/fetchProfile', null, { root: true });

    return true;
  }
  else if (error === 1 && !message) {
    const twoFAInputFields= [
          {
            placeholder: i18n.t('auth.twoFAPlaceholder'),
            name: 'twoFACode',
            it_pass: false,
            regexp: new RegExp(/[0-9|a-z]{4}/),
            equal_name: '',
            typeString: 'text',
            errorText: '',
            value: null,
          }
        ];
    commit('main/SET_MODAL_MESSAGE', {
      input_fields_title: i18n.t('auth.twoFAPlaceholder'),
      buttons: 'ok',
      title: '2FA',
      text: i18n.t('auth.twoFAText'),
      input_fields: twoFAInputFields,
      accept_button_text: i18n.t('auth.sendPasswordButton'),
      accept_action_func: '2FA',
    }, {root: true});

    return true;
  }
  else {
    commit('main/SET_MODAL_MESSAGE', {
      level: 'error',
      icon: 'x',
      buttons: 'ok',
      text: message,
      accept_action_func: 'close',
    }, {root: true});

    return false;
  }
};
