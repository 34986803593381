import '@/assets/global.scss';

import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueSession from 'vue-session';
import BootstrapVue from 'bootstrap-vue';
import VueMask from 'v-mask';

import vOutsideEvents from 'vue-outside-events';
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import {extend, localize, ValidationProvider, ValidationObserver} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import 'vue-material-design-icons/styles.css';
import VueYandexMetrika from 'vue-yandex-metrika';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import App from '@/AppSW';

import router from '@/routerSW';
import store from '@/stores';
import VueSocketIO from 'vue-socket.io-extended';
import io from 'socket.io-client';

import i18n from './i18n';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

// Install English, Franch and Russian locales.
localize({
    en: {
        messages: {
            summ: {
                'minMax': 'Summ is invalid.'
            },
        },
    },
    fr: {
        messages: {
            summ: {
                'minMax': 'Summe le invalide.'
            },
        },
    },
    ru: {
        messages: {
            summ: {
                'minMax': 'Сумма не верная.'
            },
        },
    },
});

const socket = io(document.location.origin, {path: '/socket.io'});
Vue.use(VueSocketIO, socket, {store});

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.prototype.moment = moment;

Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(VueSession, {persist: true});
Vue.use(vOutsideEvents);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(
    VueYandexMetrika,
    {
        id: process.env.VUE_APP_YANDEX_METRIKA_ID,
        router: router,
        env: process.env.NODE_ENV
    }
);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    el: '#app'
});

