import {clientApiAuth} from '@/utils/clientApiAuth';

export const doEmailActivateConfirm = async ({ commit }, payload) => {
    let response;
    const { email, code } = payload;

    try {
        response = await clientApiAuth('post', 'profile/email-activate-confirm', { email: email, code: code });

        const { error, message } = response.data;

        if (error != 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        }
        else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'i',
                buttons: 'ok',
                text: message,
                accept_action_func: 'closeVerifyEmail',
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
