import {clientApi} from '@/utils/clientApi';

export const doForgotPassword = async ({commit}, payload) => {
    let result;

    try {
        result = await clientApi('post', 'user/forgot-password', {
            data: payload
        });
    } catch (error) {
        console.log('error = ', error);
    }

    if (result) {
        let {error, message} = result.data;

        if (error !== 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
            return false;
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'i',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
            return true;
        }
    }
};
