import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  payments: [],
  paymentsMobile: {},
  paymentsCount: 0,
  paymentsType: 'deposit',
  editProfile: null,
  openSection: '',
  notifications: {},
};

export const personal = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
