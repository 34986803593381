import {clientApiAuth} from '@/utils/clientApiAuth';
import cookie from 'vue-cookies';

const accessToken = cookie.get('accessToken');

export const fetchProfile = async ({state, commit, rootGetters}) => {
    let response;
    const profile = [];

    const params = {
        headers: {
            Authorization: `${accessToken}`
        },
        data: {profile}
    };

    let currentLang = rootGetters['user/getLanguage'];

    try {
        response = await clientApiAuth('post', `user/profile?lang=${currentLang}`, params);

        if (response !== 401) {
            const {error, data} = response.data;

            if (error === 0 && response.status === 200) {
                state.profile = data;

                let currentLang = rootGetters['user/getLanguage'];

                if (data.hasOwnProperty('params')
                    && data.params !== null
                    && data.params.hasOwnProperty('language')
                    && currentLang !== data.params.language) {
                    if (rootGetters['main/getBrand'].is_change_lang) {
                        commit('SET_LANGUAGE', data.params.language);
                    }
                }
            } else {
                state.profile = {
                    birth_date: null,
                    email: null,
                    fio: null,
                    id: null,
                    is_bet: null,
                    max_bet: null,
                    name: null,
                    new_password: null,
                    params: null,
                    phone: '',
                    reg_status: null,
                };
            }
        }
        if (response !== 401) {
            if (rootGetters['auth/isAuthUser'] !== true) {
                commit('auth/SET_AUTH_USER', true, {root: true});
            }
        } else {
            if (rootGetters['auth/isAuthUser'] !== false) {
                commit('auth/SET_AUTH_USER', false, {root: true});
            }
        }
    } catch (error) {
        // ignore
    }
};
