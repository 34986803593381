/* eslint-disable */
import {clientApi} from '@/utils/clientApi';

export const fetchEditProfile = async ({commit, state}) => {
    let response;

    try {
        response = await clientApi('get', 'profile/get-profile');
        const {error, data} = response.data;

        if (error === 0) {
            state.editProfile = data;
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: error,
                accept_action_func: 'close',
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }

    return null;
};
