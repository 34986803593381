import {clientApi} from '@/utils/clientApi';

export const fetchGameFavorite = async (gameId) => {
    try {
        let response = await clientApi('get', `game/is-favorites?gameId=${gameId}`);

        if (response.error === 0) {
            return  response.data;
        } else {
            //@TODO
        }
    } catch (error) {
        console.log(error);
        throw error; // Важно передать ошибку дальше - чтобы было понятно в чем причина отказа
    }
};