import {clientApi} from '@/utils/clientApi';

export const doPay = async ({commit, dispatch}, payload) => {
    const {type, source, amount, pan} = payload;

    try {
        let response = await clientApi('get', `payment/index?source=${source}&type=${type}&pan=${pan}&amount=${amount}`);

        const {data, status} = response;

        if (status === 200) {
            if (data.hasOwnProperty('error') && data.error > 0) {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'error',
                    icon: 'x',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
            } else {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'info',
                    icon: 'i',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
                await dispatch('fetchPayStatus', data.data.trx_id);
            }
        } else {
            if (data.hasOwnProperty('message')) {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'error',
                    icon: 'x',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
            }
        }
    } catch (error) {
        console.log(error);
    }
};
