import {clientApi} from '@/utils/clientApi';

export const fetchLaunchToken = async ({commit, state}, payload) => {
    try {
        let response = await clientApi(
            'get',
            'user/launch-token?gameId=' + payload
        );
        if (response) {
            const {token, error, message} = response.data;

            if (typeof error !== 'undefined' && error != null) {
                if (error === 401) {
                    commit('auth/SET_SIGNIN_VISIBLE', true, {root: true});
                } else {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            } else if (typeof token === 'string') {
                state.launchToken = token;
                return true;
            }
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
    return false;
};