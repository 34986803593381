import {clientApi} from '@/utils/clientApi';

export const fetchGameList = async ({commit, state}, gameId = null) => {

    const params =
        {
            is_new: state.isNewFilter,
            is_favorites: state.isFavoritesFilter,
            search_string: state.searchString,
            page: 1,
            limit: state.listLimit,
            get_list_for_game: gameId
        };

    if (state.additionaFilters) {
        if (state.additionaFilters.indexOf('Slots') > -1) {
            params.is_slot = true;
        }
        if (state.additionaFilters.indexOf('Pyramid') > -1) {
            params.is_pyramid = true;
        }
        if (state.additionaFilters.indexOf('Blackjack') > -1) {
            params.is_21 = true;
        }
        if (state.additionaFilters.indexOf('Bingo') > -1) {
            params.is_bingo = true;
        }
        if (state.additionaFilters.indexOf('Roulette') > -1) {
            params.is_roullete = true;
        }
        if (state.additionaFilters.indexOf('Mini games') > -1) {
            params.is_minigame = true;
        }
    }
    try {
        let response = await clientApi(
            'post',
            'game/list',
            {search: params}
        );
        if (response) {
            const {data, error, search} = response.data;
            if (error === null) {
                if (typeof search.count === 'number') {
                    commit('SET_LIST_COUNT', search.count);
                }
                else {
                    commit('SET_LIST_COUNT', 0);
                }

                commit('SET_LIST', data);
            }
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};